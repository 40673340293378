<template>
  <v-card class="pa-3 mb-3">
    <h2 class="title">{{$t('verificaciones.adjuntar')}}</h2>
    <v-divider class="mb-2"></v-divider>
    <template v-if="!contrato.verificado">
      <DragAndDrop accept=".mp3,audio/*" v-model="documentoVerificacion" />
      <template v-if="documentoVerificacion">
        <v-divider class="my-2"></v-divider>
        <v-card outlined class="pa-2">
          <v-card-title>{{ documentoVerificacion.name }}</v-card-title>
        </v-card>
        <div class="d-flex mt-2">
          <v-btn @click.stop="documentoVerificacion = null" color="error darken-2" small>
            <v-icon left>mdi-close</v-icon>
            {{$t('common.eliminar')}}
          </v-btn>
          <v-btn @click.stop="enviar" color="secondary" class="ml-auto" small>
            <v-icon left>mdi-upload</v-icon>
            {{$t('common.guardar')}}
          </v-btn>
        </div>
      </template>
    </template>
    <template v-if="contrato.verificado && contrato.archivo_verificacion">
      <v-btn
        @click="descargarVerificacion"
        color="secondary"
        outlined
        class="pa-2"
      >
        <v-icon left>mdi-download</v-icon>
        {{ contrato.archivo_verificacion }}
      </v-btn>
    </template>
  </v-card>
</template>

<script>
import { downloadFile } from "@/utils/index.js";

export default {
  components: {
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
  },
  props: {
    contrato: Object,
  },
  data() {
    return {
      documentoVerificacion: null,
    };
  },
  methods: {
    async descargarVerificacion() {
      try {
        const { data } = await axios({
          method: "GET",
          url: `/contratos/verificacion/${this.contrato.archivo_verificacion}`,
          responseType: "blob",
        });
				const file = new File( [ data ], this.contrato.archivo_verificacion )
        downloadFile(file, false);
      } catch {
        this.$root.$emit("snack", this.$t('verificaciones.not_found'));
      }
    },
    async enviar() {
      axios({
        method: "POST",
        url: `/contratos/verificacion/${this.contrato.ref}`,
        headers: { "Content-Type": "multipart/form-data" },
				data: {
					file: this.documentoVerificacion
				}
      })
        .then(() => {
          this.$emit("reload");
        })
        .catch(() => {
          this.$root.$emit("snack", this.$t('verificaciones.error_subir'));
        });
    },
  },
};
</script>

<style></style>
